import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import { between } from 'polished';
import { FaLongArrowAltRight } from 'react-icons/fa';
import Img from 'gatsby-image';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import CategorySection from '../components/category-section';
import PostList from '../components/post-list';
import Thumbnail from '../components/thumbnail';
import Container from '../components/container';
import SEO from '../components/seo';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('400px', '650px')};
`;

const TownPage = ({ data, pageContext }) => {
	const town = data.mysqlTown;
	const categories = data.allMysqlCategory.edges;

	if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('lastUserTown', JSON.stringify({ value: town.slug, label: town.label_nominative }));

	return (
		<Page>
			<SEO title={town.label_nominative} description={town.seo_description} canonical={pageContext.canonical} />
			<StyledHeaderSection hero="/hero-town.jpg">
				<HeaderFront>
					<Navigation />
					<Container>
						<HeaderHeading>{town.label_nominative}</HeaderHeading>
						<HeaderDescription>{town.description}</HeaderDescription>
					</Container>
				</HeaderFront>
			</StyledHeaderSection>

			{categories.map(({ node }, index) => (
				<CategorySection key={index} className="slant--top slant--bottom">
					<div className="split">
						<div className="content">
							<h2>
								<Link to={`/${node.slug}/${town.slug}/`}>{node.label_nominative}</Link>
							</h2>
							<p>
								{node.description}
							</p>
							<Link to={`/${node.slug}/${town.slug}/`}>
								Zobacz wszystkie oferty <FaLongArrowAltRight />
							</Link>
						</div>

						<Link to={`/${node.slug}/${town.slug}/`} className="aside" tabIndex="-1">
							<Img fluid={data[`cat${node.uid}`].childImageSharp.fluid} loading="lazy" alt={node.label_nominative} />
						</Link>
					</div>

					<Container>
						<PostList>
							{pageContext.posts[node.slug].map(post => (
								<Thumbnail key={post.node.slug} post={post.node} />
							))}
						</PostList>
					</Container>
				</CategorySection>
			))}
		</Page>
	);
};

export default TownPage;

export const query = graphql`
	query TownPage($id: Int!) {
		mysqlTown(uid: {eq: $id}) {
			slug
			label_nominative
			label_in
			description
			seo_description
		}

		allMysqlCategory {
			edges {
				node {
					uid
					slug
					label_nominative
					description
				}
			}
		}

		cat1: file(relativePath: { eq: "splash-music.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat2: file(relativePath: { eq: "splash-decor.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat3: file(relativePath: { eq: "splash-fashion-beauty.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat4: file(relativePath: { eq: "splash-foto-video.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat5: file(relativePath: { eq: "splash-gastronomy.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat6: file(relativePath: { eq: "splash-cars.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat7: file(relativePath: { eq: "splash-wedding-venues.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}

		cat8: file(relativePath: { eq: "splash-miscellaneous.png" }) {
			childImageSharp {
				fluid(maxWidth: 300) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
