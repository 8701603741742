import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { FaLongArrowAltRight, FaMapMarkerAlt } from 'react-icons/fa';

const StyledArticle = styled.article`
	background: ${props => props.theme.colors.white};
	border-radius: ${props => props.theme.border_radius};
	position: relative;
	bottom: 0;
	color: ${props => props.theme.colors.warm_grey_800};
	box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
		0 2px 15px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)};
	transition: all 0.3s;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			bottom: 3px;
			box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
				0 2px 35px ${props => props.theme.colors.warm_grey_800 && transparentize(0.7, props.theme.colors.warm_grey_800)};
		}

		&.not-accepted:hover {
			bottom: 0;
			box-shadow: 0 1px 50px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)},
		0 2px 15px ${props => props.theme.colors.warm_grey_800 && transparentize(0.9, props.theme.colors.warm_grey_800)};
		}
	}
`;

const Image = styled.img`
	width: 100%;
	height: 250px;
	object-fit: cover;
	border-top-left-radius: ${props => props.theme.border_radius};
	border-top-right-radius: ${props => props.theme.border_radius};
`;

const Padding = styled.div`
	padding: 9px 13px 13px;
	text-align: center;

	h3 {
		margin: 0;
		text-align: left;
		font-size: 12px;
		font-weight: 400;
		text-transform: uppercase;
		font-family: 'Roboto', sans-serif;
	}

	a {
		color: ${props => props.theme.colors.warm_grey_800};
		font-weight: 400;
	}

	ul {
		list-style-type: none;
		margin: 0 0 70px;

		li {
			svg {
				color: ${props => props.theme.colors.warm_grey_300};
				margin-right: 3px;
			}

			.radius {
				color: ${props => props.theme.colors.warm_grey_400};
				margin-left: 10px;
			}
		}
	}
`;

const Price = styled.div`
	font-size: 30px;
	margin: 23px 0;

	small {
		font-size: 12px;
		margin-right: 3px;
	}

	span {
		font-weight: 700;
	}

	a {
		display: block;

		&:hover,
		&:focus {
			text-decoration: none;
			outline: none;
		}
	}
`;

const Button = styled(Link)`
	background: ${props => props.theme.colors.warm_grey_100};
	width: 100%;
	display: block;
	text-align: center;
	padding: 23px 0;
	margin-top: 23px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	bottom: 0;
	position: absolute;
	font-weight: 700;
	border-bottom-left-radius: ${props => props.theme.border_radius};
	border-bottom-right-radius: ${props => props.theme.border_radius};
	transition: all 0.3s;

	&:hover,
	&:focus {
		background: ${props => props.theme.colors.golden_500};
		color: ${props => props.theme.colors.white};
		outline: none;
		text-decoration: none;
	}
`;

const FakeButton = styled.div`
	background: ${props => props.theme.colors.warm_grey_100};
	width: 100%;
	color: ${props => props.theme.colors.warm_grey_300};
	text-align: center;
	padding: 23px 0;
	margin-top: 23px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	bottom: 0;
	position: absolute;
	font-weight: 700;
	border-bottom-left-radius: ${props => props.theme.border_radius};
	border-bottom-right-radius: ${props => props.theme.border_radius};
	transition: all 0.3s;
`;

class Thumbnail extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			post: props.post,
			isAccepted: true,
		};

		if (props.post.hasOwnProperty('accepted') && props.post.accepted === 0) this.state.isAccepted = false;
	}

	render() {
		return (
			<StyledArticle className={`${this.props.className} ${this.state.isAccepted ? '': 'not-accepted'}` }>
				{ this.state.isAccepted &&
					<Link to={`/${this.state.post.category_slug}/${this.state.post.slug}/`}>
						<Image src={'https://res.cloudinary.com/weselnicy/image/upload/c_scale,w_720,q_40/' + (this.state.post.photo || this.state.post.photos.split(', ')[0])} alt={this.state.post.title} />
					</Link>
				}

				{ !this.state.isAccepted && 
					<Image src={'https://res.cloudinary.com/weselnicy/image/upload/c_scale,w_720,q_40/' + (this.state.post.photo || this.state.post.photos.split(', ')[0])} alt={this.state.post.title} />
				}

				<Padding>
					<h3>
						{ this.state.isAccepted &&
							<Link to={`/${this.state.post.category_slug}/${this.state.post.slug}/`}>{this.state.post.title}</Link>
						}
						
						{ !this.state.isAccepted && <>{this.state.post.title}</> }
					</h3>

					<Price>
						{ this.state.isAccepted &&
							<Link to={`/${this.state.post.category_slug}/${this.state.post.slug}/`}>
								{ this.state.post.price_from === 1 && <small>od </small> }
								<span>{this.state.post.price} zł</span>
							</Link>
						}

						{ !this.state.isAccepted &&
							<>
								{ this.state.post.price_from === 1 && <small>od </small> }
								<span>{this.state.post.price} zł</span>
							</>
						}
					</Price>

					<ul>
						<li>
							<FaMapMarkerAlt />
							<Link to={`/${this.state.post.category_slug}/${this.state.post.town_slug}/`}>{this.state.post.town_label}</Link>
							{this.state.post.radius === -1 &&
								<span className="radius" tooltip="Działamy na terenie całej Polski">+cała Polska</span>
							}
							{this.state.post.radius > 0 &&
								<span className="radius" tooltip={'Działamy w promieniu ' + this.state.post.radius + 'km'}>+{this.state.post.radius} km</span>
							}
						</li>
					</ul>
				</Padding>

				{ this.state.isAccepted &&
					<Button to={`/${this.state.post.category_slug}/${this.state.post.slug}/`}>
						Zobacz szczegóły <FaLongArrowAltRight />
					</Button>
				}

				{ !this.state.isAccepted &&
					<FakeButton>
						Zobacz szczegóły <FaLongArrowAltRight />
					</FakeButton>
				}
			</StyledArticle>
		);
	}
}

export default Thumbnail;
