import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';
import { FaPlus } from 'react-icons/fa';

const Grid = styled.div`
	position: relative;
	z-index: 220;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
	grid-gap: ${between('23px', '30px')};
`;

const StyledLink = styled(Link)`
	background: ${props => props.theme.colors.white};
	height: 100%;
	min-height: 465px;
	text-align: center;
	position: relative;
	border: dashed 2px ${props => props.theme.colors.warm_grey_200};
	border-radius: ${props => props.theme.border_radius};
	transition: all 0.3s;

	.wrapper {
		margin: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.icon {
			font-size: 36px;
		}
	}

	&:hover {
		background: ${props => props.theme.colors.warm_grey_100};
		border-color: ${props => props.theme.colors.warm_grey_300};

		p {
			text-decoration: underline;
		}
	}
`;

const AddOfferThumbnail = () => {
	return (
		<StyledLink to="/dodaj-oferte/">
			<div className="wrapper">
				<FaPlus className="icon" />
				<p>Dodaj własną ofertę</p>
			</div>
		</StyledLink>
	);
};

const PostList = ({ children }) => {
	return (
		<Grid>
			{children}

			{children.length < 3 && <AddOfferThumbnail key="add-offer-placeholder" />}

			{children.length < 3 && Array.apply(null, Array(2 - children.length)).map((el, i) => (
				<div key={'empty-' + i} className="empty"></div>
			))}

			{children.length > 3 && <AddOfferThumbnail key="add-offer-placeholder" />}
		</Grid>
	);
};

PostList.propTypes = {
	children: PropTypes.node.isRequired,
};

export default PostList;
