import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';

const StyledCategorySection = styled.section`
	position: relative;
	padding-bottom: ${between('30px', '40px')};

	&:first-of-type {
		padding-top: 0;
	}

	&:nth-child(odd) {
		background: ${props => props.theme.colors.warm_grey_100};
		padding-top: ${between('30px', '80px')};
	}

	.split {
		margin: 0 auto;
		position: relative;
		z-index: 200;
		width: ${between('300px', '1200px')};
		padding-left: ${between('10px', '46px')};
		padding-right: ${between('10px', '46px')};

		.content {
			h2 {
				font-size: ${between('26px', '52px')};
				margin-bottom: ${between('26px', '32px')};

				a {
					color: ${props => props.theme.colors.warm_grey_1000};
					text-decoration: none !important;
				}

				&::after {
					content: '';
					background: ${props => props.theme.colors.golden_500};
					width: 70px;
					display: block;
					z-index: -1;
					height: ${between('1px', '2px')};
					margin-top: ${between('10px', '18px')};
				}
			}

			p {
				line-height: 32px;
				max-width: 660px;

				& + a {
					display: inline-block;
					margin-top: 23px;
					font-weight: 700;
					text-transform: uppercase;
					font-size: 14px;
					letter-spacing: 3px;
					user-select: none;
				}
			}
		}

		.aside {
			display: none;
			margin-top: -120px;
			outline: none;
			opacity: 0.4;
			transition: all 0.3s;

			img {
				max-width: 100%;
				height: auto;
				user-select: none;
				filter: grayscale(0.5);
			}
		}

		& + * {
			margin-top: 30px;
		}
	}

	&:hover .aside {
		opacity: 0.6;
	}

	@media (min-width: ${props => props.theme.breakpoints.lg}) {
		.split {
			width: auto;
			max-width: 2000px;
			padding: 0;
			display: grid;
			grid-template-columns: 60% 40%;

			.content {
				padding-left: 100px;
				padding-right: 100px;
			}

			.aside {
				display: block;
			}

			& + * {
				min-height: 110px;
			}
		}

		&:nth-child(odd) {
			.split {
				grid-template-columns: 40% 60%;

				.content {
					order: 1;
				}
			}
		}

		@media (min-width: ${props => props.theme.breakpoints.xl}) {
			.split + * {
				margin-top: -90px;
				margin-bottom: 30px;
			}
		}
	}
`;

const CategorySection = ({ className, children }) => (
	<StyledCategorySection className={className}>
		{children}
	</StyledCategorySection>
);

CategorySection.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CategorySection;
